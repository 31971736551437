import { environment } from '@/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalStorageService } from '@app/@core/services/storage/local-storage.service';
import { TOKEN_STORAGE_KEY } from '@app/@core/services/storage/storage-keys';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../storage/local-storage.service";
var ApiService = /** @class */ (function () {
    function ApiService(http, router, storage) {
        this.http = http;
        this.router = router;
        this.storage = storage;
        this.STATUS_UNAUTHORIZED = 401;
        this.STATUS_NO_CONTENT = 204;
        if (environment.hasOwnProperty('apiMockAll') && environment['apiMockAll'] === true) {
            this.apiEndpoint = environment['apiMockEndpoint'];
        }
        else {
            this.apiEndpoint = environment.apiEndpoint;
        }
    }
    ApiService.prototype.get = function (url, options) {
        if (options === void 0) { options = {}; }
        return this.resolveApiResponse(this.http.get("" + this.apiEndpoint + url, { params: options }));
    };
    ApiService.prototype.post = function (url, options) {
        if (options === void 0) { options = {}; }
        return this.resolveApiResponse(this.http.post("" + this.apiEndpoint + url, options));
    };
    ApiService.prototype.put = function (url, options) {
        return this.resolveApiResponse(this.http.put("" + this.apiEndpoint + url, options));
    };
    ApiService.prototype.patch = function (url, options) {
        return this.resolveApiResponse(this.http.patch("" + this.apiEndpoint + url, options));
    };
    ApiService.prototype.delete = function (url, options) {
        var request = this.http.request('delete', "" + this.apiEndpoint + url, { body: options });
        // return this.resolveApiResponse(this.http.delete(`${this.apiEndpoint}${url}`, {params: options}));
        return this.resolveApiResponse(request);
    };
    ApiService.prototype.resolveApiResponse = function (data) {
        var _this = this;
        return new Observable(function (observer) {
            data.subscribe(function (response) {
                // TODO: tohle je hnusne, hnusne, hnusne, vyresit error handling jako clovek
                // if (response && (response['result'] !== undefined || response['data'] !== undefined || response['id'] !== undefined || response['isAdmin'] !== undefined  || response.length > -1)) {
                //   observer.next(response);
                // } else {
                //   observer.error(response);
                // }
                observer.next(response);
                observer.complete();
            }, function (error) {
                if (error.status === _this.STATUS_UNAUTHORIZED || error.status === 403) {
                    _this.storage.remove(TOKEN_STORAGE_KEY);
                    if (!_this.router.url.includes('/login')) {
                        // TODO: vymazat vsechny puvodni login veci v celem projektu az bude hotove
                        // this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url}});
                    }
                }
                observer.error(_this.parseErrorResponse(error));
                observer.complete();
            });
        });
    };
    ApiService.prototype.parseErrorResponse = function (error) {
        console.log({ error: error });
        var errorResponse = error['error'];
        if (typeof errorResponse === 'string') {
            errorResponse = {
                error: { message: errorResponse }
            };
        }
        if (errorResponse && errorResponse['error'] === undefined) {
            errorResponse['error'] = {};
        }
        if (errorResponse && errorResponse['error']['message'] === undefined) {
            errorResponse['error']['message'] = 'Undefined error';
        }
        return errorResponse;
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient), i0.inject(i2.Router), i0.inject(i3.LocalStorageService)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
