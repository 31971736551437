import { User } from '@/app/@core/model/user.model';
import UserResponse from '@/app/pages/users/model/user-response.model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiService } from '@app/@core/services/api/auth-api.service';
import { LocalStorageService } from '@app/@core/services/storage/local-storage.service';
import { ADMIN_STORAGE_KEY, BRANCH_ID_STORAGE_KEY } from '@app/@core/services/storage/storage-keys';
import { Time } from '@app/@core/utils/Time';
import { cache, invalidateCache } from '@app/@core/utils/tscache/cache';
import { ApiService } from '@app/@core/services/api/api.service';
import { CrudSingleService } from '@app/@core/services/crud/crud-single.service';
import { ETags } from '@app/shared/types/ETags';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserBranch } from './models/user-branch.model';

@Injectable({
  providedIn: 'root'
})
export class UserMeService extends CrudSingleService {
  public user: UserResponse;
  public userInfo: User = null;

  public dataSubject: BehaviorSubject<User>;

  constructor(
    protected api: ApiService,
    private translate: TranslateService,
    private router: Router,
    private storage: LocalStorageService,
    private authApi: AuthApiService,
  ) {
    super(api);
    this._urlNameSpace = '/user-info';
    this.supportedMethods = ['get', 'update'];
    this.dataSubject = new BehaviorSubject<User>(null);
  }

  public setLang(lang: string): Observable<User> {
    return new Observable(observer => {
      this.getUserInfo(true).then((userInfoResponse) => {
        userInfoResponse.user.lang = lang;

        // TODO: lang
        this.api.put(this._urlNameSpace, userInfoResponse).subscribe((data) => {
          observer.next(data['data']);
        });
      });
    });
  }

  public setUserInfo(userInfo): void {
    try {
      const parsed = JSON.parse(userInfo.user_info);
      this.userInfo = parsed;
    } catch (e) {
      console.error(e);
    }
  }

  @cache(Time.MINUTE * 10, [ETags.USER_INFO])
  public async getUserInfo(fromCache: boolean = false): Promise<UserResponse> {
    try {

      // TODO: normalize starting slash in url
      const user = await this.authApi.get('users/user-info').toPromise();
      const companyName = await this.api.get('/users/my-company-name').toPromise();
      // TODO: remove this and fix model.
      const response: UserResponse = {
        user: user,
        company: {
          name: companyName,
          id: user.companyId
        },
      };

      const userRole = await this.api.get('/users/my-role').toPromise();
      response.user.role = userRole.name;
      if (!this.storage.get(BRANCH_ID_STORAGE_KEY)) {
        this.storage.store(BRANCH_ID_STORAGE_KEY, userRole.branchId);
      }
      this.storage.store(ADMIN_STORAGE_KEY, userRole.isAdmin);

      this.translate.use(response.user.lang);
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  public async getBranches(): Promise<UserBranch[]> {
    const userResponse = await this.getUserInfo();
    return await this.api.get(`/users/${userResponse.user.id}/branches/`).toPromise() as UserBranch[];
  }
}
