import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './@core/services/auth/auth.service';
import { AnalyticsService } from '@app/@core/utils';
import { ELanguage } from '@app/shared/types/ELanguage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public defaultLang = ELanguage.CZECH;
  public notificationOptions = {
    timeOut: 5000,
    showProgressBar: false,
    preventDuplicates: true,
    maxStack: 6,
  };

  constructor(
    authService: AuthService,
    private translate: TranslateService,
    private userMeService: UserMeService,
    private analytics: AnalyticsService,
    private router: Router,
  ) {
    translate.setDefaultLang(this.defaultLang);

    authService.authNavStatus$.subscribe((isLogged: boolean) => {
      if (isLogged) {
        const response = userMeService.getUserInfo();
        response.then((userInfoResponse) => {
          const lang = userInfoResponse.user.lang;

          if (lang !== null) {
            this.translate.use(lang);
          } else {
            this.userMeService.setLang(this.translate.defaultLang).subscribe();
          }
        });

        router.events.subscribe((val) => {
          if (val instanceof NavigationStart) {
            if (!authService.isAuthenticated()) {
              authService.logOut();
            }
          }
        });
      } else {
        translate.use(translate.getBrowserLang());
      }
    });
  }

  public ngOnInit(): void {
    this.analytics.trackPageViews();
  }
}
