import { Component, Input, OnInit } from '@angular/core';
import { IdentifiedEntityCollectionSchemaInterface } from '@app/@core/schema/identified-entity-collection.schema.interface';
import { EntityNavigatorService } from '@app/@core/services/entity-navigator/entity-navigator.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-entity-detail-table',
  templateUrl: './entity-detail-table.component.html',
  styleUrls: ['./entity-detail-table.component.scss']
})
export class EntityDetailTableComponent implements OnInit {

  @Input() public schema: IdentifiedEntityCollectionSchemaInterface;
  @Input() public entity: any;
  @Input() public hideCrudActions = false;
  @Input() public hiddenProperties = [];

  constructor(
    private navigator: EntityNavigatorService,
    public translate: TranslateService,
  ) {
  }

  public getEntityValue(data: any, key: string): any {
    if (key.length === 0) {
      console.error('No key specified!');
      return null;
    }

    const keyParts = key.split('.');

    let out = data;
    for (const keyPart of keyParts) {

      if (out === null || !out || typeof out === 'undefined') {
        return null;
      }

      if (out.hasOwnProperty(keyPart)) {
        out = out[keyPart];
        continue;
      }

      return null;
    }

    return out;
  }

  public onEdit(): void {
    this.navigator.edit(this.schema, this.entity);
  }

  public onRemove(): void {
    this.navigator.remove(this.schema, this.entity).subscribe();
  }

  public ngOnInit(): void {

  }

}
