import { cacheTags, CreateCacher } from './cacher';
/**
 * This decorator create method which do only first request for data and others cache -
 * save to queue and resolve it after receiving data. After first success data fetch it
 * return immediately resolved promise. Cached values are only for duplicate arguments which
 * are passed to dataGetter function.
 *
 * @param {?number} [cacheTimeout=60000] Cached data maximal age in milliseconds. Default is 60 seconds.
 * @param {string[]} [tags=[]] Array og tags used for invalidation by invalidateCache annotation
 */
export function cache(cacheTimeout, tags) {
    if (cacheTimeout === void 0) { cacheTimeout = 60000; }
    if (tags === void 0) { tags = []; }
    return function (target, keyName, descriptor) {
        return {
            value: CreateCacher(descriptor.value, cacheTimeout, tags),
        };
    };
}
export function invalidateCache(tags) {
    return function (target, keyName, descriptor) {
        return {
            value: function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                var invalidate = function () {
                    for (var _i = 0, tags_1 = tags; _i < tags_1.length; _i++) {
                        var tag = tags_1[_i];
                        cacheTags[tag] = new Date().getTime();
                    }
                };
                var result = descriptor.value.apply(this, args);
                // Invalidate after Promise is resolved if is returned
                if (typeof result === 'object' && typeof result.then === 'function') {
                    result.then(function (response) {
                        invalidate();
                        return response;
                    });
                }
                else {
                    invalidate();
                }
                return result;
            },
        };
    };
}
export { CreateCacher };
