import { AuthService } from '@/app/@core/services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureLevelService } from '@app/@core/services/feature-level/feature-level.service';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import { LayoutService } from '@app/@core/utils';
import { NbMenuItem, NbSidebarService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { EUserRole } from '@app/shared/types/EUserRole';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  public menuItems: NbMenuItem[] = [];

  constructor(
    private router: Router,
    public features: FeatureLevelService,
    public translate: TranslateService,
    public auth: AuthService,
    private userMeService: UserMeService,
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService
  ) {
  }

  public async ngOnInit(): Promise<void> {
    await this.loadMenuItems();
  }

  public navigate(url: string): Promise<boolean> {
    return this.router.navigate([url]);
  }

  private async loadMenuItems(): Promise<void> {
    const menuItems = await this.getMenuItems();

    for (const each of menuItems) {
      this.menuItems.push(each);
      this.translate.stream(each.title).subscribe(res => { each.title = res; });

      if (each.hasOwnProperty('children')) {
        for (const eachChild of each.children) {
          this.translate.stream(eachChild.title).subscribe(res => {
            eachChild.title = res;
          });
        }
      }
    }
  }

  private async getMenuItems(): Promise<NbMenuItem[]> {
    const response = await this.userMeService.getUserInfo();

    if (response.user.role === EUserRole.INVENTURA) {
      this.sidebarService.collapse('menu-sidebar');
      this.layoutService.changeLayoutSize();

      return[ {
        title: 'page.stockTaking.title',
        link: '/stock-taking',
        icon: 'fa fa-file'
      } ];
    } else {
      return[
        {
          title: 'dashboard.title',
          link: '/',
          icon: 'fa fa-home',
          home: true,
        },
        {
          title: 'branches.title',
          link: '/branches',
          icon: 'fa fa-building',
          hidden: !this.auth.isAdmin()
        },
        {
          title: 'stocks.title',
          link: '/stocks',
          icon: 'fa fa-warehouse',
        },
        {
          title: 'stockCards.title',
          link: '/stock-cards',
          icon: 'fa fa-cube'
        },
        {
          title: 'recipes.title',
          link: '/recipes',
          icon: 'fa fa-book'
        },
        // TODO: uncomment when implemented
        // {
        //   title: 'suppliers.title',
        //   link: '/suppliers',
        //   hidden: !this.features.isAvailable(this.features.LEVEL_DEV),
        //   icon: 'fa fa-truck'
        // },
        // {
        //   title: 'users.title',
        //   link: '/users',
        //   icon: 'fa fa-user',
        //   hidden: !this.auth.isAdmin()
        // },
        // {
        //   title: 'page.stats.title',
        //   link: '/stats',
        //   hidden: !this.features.isAvailable(this.features.LEVEL_DEV),
        //   icon: 'fa fa-chart-bar'
        // },
        // {
        //   title: 'page.reports.title',
        //   link: '/reports',
        //   hidden: !this.features.isAvailable(this.features.LEVEL_DEV),
        //   icon: 'fa fa-file'
        // },
        {
          title: 'page.stockTaking.title',
          link: '/stock-taking',
          icon: 'fa fa-file'
        },
        {
          title: 'externalLink.groupTitle',
          group: true,
        },
        {
          title: 'externalLink.cinkCloud',
          url: 'https://cink.cloud/',
          target: '_blank',
          icon: 'fa fa-dollar-sign'
        } ];
    }
    }
}
