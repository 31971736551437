/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@nebular/theme/components/user/user.component.ngfactory";
import * as i3 from "@nebular/theme/components/user/user.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@nebular/theme/components/cdk/overlay/dynamic/dynamic-overlay";
import * as i6 from "@nebular/theme/components/cdk/overlay/overlay";
import * as i7 from "@nebular/theme/components/cdk/overlay/dynamic/dynamic-overlay-handler";
import * as i8 from "@nebular/theme/components/cdk/overlay/overlay-position";
import * as i9 from "@nebular/theme/components/cdk/overlay/overlay-trigger";
import * as i10 from "@nebular/theme/components/context-menu/context-menu.directive";
import * as i11 from "@nebular/theme/components/menu/menu.service";
import * as i12 from "@angular/common";
import * as i13 from "./user-menu.component";
import * as i14 from "../../../@core/services/auth/auth.service";
import * as i15 from "@angular/router";
import * as i16 from "../../../@core/services/user-me/user-me.service";
import * as i17 from "angular2-notifications";
import * as i18 from "@ngx-translate/core";
var styles_UserMenuComponent = [i0.styles];
var RenderType_UserMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserMenuComponent, data: {} });
export { RenderType_UserMenuComponent as RenderType_UserMenuComponent };
function View_UserMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "nb-user", [["nbContextMenuTag", "userMenu"], ["nbContextMenuTrigger", "hover"]], [[2, "inverse", null], [2, "small", null], [2, "medium", null], [2, "large", null], [2, "xlarge", null]], null, null, i2.View_NbUserComponent_0, i2.RenderType_NbUserComponent)), i1.ɵdid(1, 49152, null, 0, i3.NbUserComponent, [i4.DomSanitizer], { name: [0, "name"] }, null), i1.ɵprd(512, null, i5.NbDynamicOverlay, i5.NbDynamicOverlay, [i6.NbOverlayService, i1.ComponentFactoryResolver, i1.NgZone]), i1.ɵprd(512, null, i7.NbDynamicOverlayHandler, i7.NbDynamicOverlayHandler, [i8.NbPositionBuilderService, i9.NbTriggerStrategyBuilderService, i5.NbDynamicOverlay]), i1.ɵdid(4, 4931584, null, 0, i10.NbContextMenuDirective, [i1.ElementRef, i11.NbMenuService, i7.NbDynamicOverlayHandler], { tag: [0, "tag"], items: [1, "items"], trigger: [2, "trigger"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = ((_co.user == null) ? null : _co.user.fullName); _ck(_v, 1, 0, currVal_5); var currVal_6 = "userMenu"; var currVal_7 = _co.menuItems; var currVal_8 = "hover"; _ck(_v, 4, 0, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inverseValue; var currVal_1 = i1.ɵnov(_v, 1).small; var currVal_2 = i1.ɵnov(_v, 1).medium; var currVal_3 = i1.ɵnov(_v, 1).large; var currVal_4 = i1.ɵnov(_v, 1).xlarge; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_UserMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserMenuComponent_1)), i1.ɵdid(1, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoading; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UserMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-menu", [], null, null, null, View_UserMenuComponent_0, RenderType_UserMenuComponent)), i1.ɵdid(1, 245760, null, 0, i13.UserMenuComponent, [i14.AuthService, i15.Router, i16.UserMeService, i17.NotificationsService, i18.TranslateService, i11.NbMenuService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserMenuComponentNgFactory = i1.ɵccf("app-user-menu", i13.UserMenuComponent, View_UserMenuComponent_Host_0, {}, {}, []);
export { UserMenuComponentNgFactory as UserMenuComponentNgFactory };
