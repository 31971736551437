export class Time {
  public static readonly SECOND = 1000;
  public static readonly MINUTE = 60 * Time.SECOND;
  public static readonly HOUR = 60 * Time.MINUTE;
  public static readonly DAY = 24 * Time.HOUR;
  public static readonly WEEK = 7 * Time.DAY;

  protected hour = 0;
  protected minute = 0;
  protected second = 0;

  constructor() {

  }
}
