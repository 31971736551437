import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '@app/@core/services/auth/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth/auth.service";
var TokenInterceptor = /** @class */ (function () {
    function TokenInterceptor(auth) {
        this.auth = auth;
    }
    TokenInterceptor.prototype.intercept = function (request, next) {
        var token = this.auth.getToken();
        request = request.clone({
            headers: new HttpHeaders({
                'Authorization': "Bearer " + token,
                'branch-id': this.auth.getBranchId(),
            })
        });
        return next.handle(request);
    };
    TokenInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function TokenInterceptor_Factory() { return new TokenInterceptor(i0.inject(i1.AuthService)); }, token: TokenInterceptor, providedIn: "root" });
    return TokenInterceptor;
}());
export { TokenInterceptor };
