import { AuthService } from '@/app/@core/services/auth/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import { Company } from '@app/pages/company/model/company.model';
import UserResponse from '@app/pages/users/model/user-response.model';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../../@core/utils';
import { UserBranch } from '@app/@core/services/user-me/models/user-branch.model';

@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() public position = 'normal';

  public company: Company;
  public branches: UserBranch[] = [];
  public companyOption: any = null;
  public currentBranch = {
    id: -1,
  };
  public isLoading = false;
  public userResponse: UserResponse;

  constructor(
    private userMeService: UserMeService,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private layoutService: LayoutService,
    public translate: TranslateService,
    public authService: AuthService,
  ) {
    const cachedId = this.authService.getBranchId();
    if (cachedId === '') {
      this.currentBranch.id = -1;
    } else {
      this.currentBranch.id = Number.parseInt(cachedId);
    }
  }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;

    try {
      this.branches.push(...await this.userMeService.getBranches());
      this.userResponse = await this.userMeService.getUserInfo(true);
      this.company = this.userResponse.company;
    } catch (e) {
      console.error(e);
    }

    if (this.isAdmin()) {
      this.companyOption = {id: -1, name: this.company.name};
    }

    this.isLoading = false;
  }

  public isAdmin(): boolean {
    return this.authService.isAdmin();
  }

  public onBranchChange(e): void {
    if (e === -1) {
      this.authService.setBranchId('');
    } else {
      this.authService.setBranchId(this.currentBranch.id.toString());
    }
    window.location.reload();
  }

  public toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  public goToHome(): void {
    this.menuService.navigateHome();
  }

}
