import UserResponse from '@/app/pages/users/model/user-response.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/@core/model/user.model';
import { AuthService } from '@app/@core/services/auth/auth.service';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import { Company } from '@app/pages/company/model/company.model';
import { NbMenuItem, NbMenuService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, OnDestroy {
  public userResponse: UserResponse;
  public user: User;
  public company: Company;
  public menuItems: NbMenuItem[] = [];
  public isLoading = false;
  private userSubscription: Subscription;

  constructor(
    private auth: AuthService,
    private router: Router,
    private userMeService: UserMeService,
    private notify: NotificationsService,
    private translate: TranslateService,
    private menuService: NbMenuService,
  ) {
    this.userSubscription = this.userMeService.dataSubject.subscribe((user) => {
      this.user = user;
    });
  }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;

    const response = await this.userMeService.getUserInfo(true);

    this.userResponse = response;
    this.user = response.user;
    this.company = response.company;
    this.loadMenuItems();
    this.menuService.onItemClick()
      .subscribe((event) => {
        if (event.tag === 'userMenu' && event.item.target === 'sign-out') {
          this.onLogOut();
        }
      });

    this.isLoading = false;
  }

  public ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  public onLogOut(): void {
    this.auth.logOut();
  }

  private loadMenuItems(): void {
    const menuItems = this.getMenuItems();

    this.menuItems = [];
    for (const each of menuItems) {
      this.menuItems.push(each);

      this.translate.stream(each.title).subscribe(res => {
        each.title = res;
      });
      if (each.hasOwnProperty('children')) {
        for (const eachChild of each.children) {
          this.translate.stream(eachChild.title).subscribe(res => {
            eachChild.title = res;
          });
        }
      }
    }
  }

  private getMenuItems(): NbMenuItem[] {
    return [
      {
        title: 'page.profile.titleShort',
        link: '/profile',
        icon: 'fa fa-user',
      },
      {
        title: 'page.company.my',
        link: this.company ? `/company/${this.company.id}/` : '#', // TODO: vyresit co s company
        icon: 'fa fa-city'
      },
      {
        title: 'button.log_out',
        target: 'sign-out',
        icon: 'fa fa-sign-out-alt',
      },
    ];
  }
}
