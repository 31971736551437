var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { environment } from '@/environments/environment';
import { ApiService } from '@app/@core/services/api/api.service';
import { AuthApiService } from '@app/@core/services/api/auth-api.service';
import { ADMIN_STORAGE_KEY, BRANCH_ID_STORAGE_KEY, FEATURES_LEVEL_KEY, TOKEN_STORAGE_KEY } from '@app/@core/services/storage/storage-keys';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from '../storage/local-storage.service';
import { UserManager } from 'oidc-client';
import * as i0 from "@angular/core";
import * as i1 from "../api/api.service";
import * as i2 from "../storage/local-storage.service";
import * as i3 from "../user-me/user-me.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../api/auth-api.service";
var AuthService = /** @class */ (function () {
    function AuthService(api, storage, userMeService, translate, authApi) {
        var _this = this;
        this.api = api;
        this.storage = storage;
        this.userMeService = userMeService;
        this.translate = translate;
        this.authApi = authApi;
        // Observable navItem source
        this._authNavStatusSource = new BehaviorSubject(false);
        // Observable navItem stream
        this.authNavStatus$ = this._authNavStatusSource.asObservable();
        this.manager = new UserManager(environment.clientAuthSettings);
        this.jwtHelper = new JwtHelperService();
        this.user = null;
        this.userId = null;
        this.manager.events.addUserSignedOut(function () {
            _this.clearStorage();
            _this.manager.signoutRedirect();
        });
        this.manager.getUser().then(function (user) {
            _this.user = user;
            _this._authNavStatusSource.next(_this.isAuthenticated());
        });
    }
    AuthService.prototype.isAuthenticated = function () {
        var isAuthenticated = this.user !== null && !this.user.expired;
        if (isAuthenticated && this.userId === null && this.user !== null) {
            // TODO: do not duplicate token and keep it in user? Then inject User to work with it?
            this.storage.store(TOKEN_STORAGE_KEY, this.user.access_token);
        }
        return isAuthenticated;
    };
    AuthService.prototype.isAdmin = function () {
        return this.storage.get(ADMIN_STORAGE_KEY) === 'true';
    };
    AuthService.prototype.getToken = function () {
        var token = this.storage.get(TOKEN_STORAGE_KEY);
        return token !== null ? token : '';
    };
    AuthService.prototype.getBranchId = function () {
        var branchId = this.storage.get(BRANCH_ID_STORAGE_KEY);
        // @ts-ignore
        if (branchId === 0 || branchId === '0') {
            branchId = '';
        }
        return branchId !== null ? branchId : '';
    };
    AuthService.prototype.setBranchId = function (id) {
        this.storage.store(BRANCH_ID_STORAGE_KEY, id);
    };
    AuthService.prototype.getUsers = function () {
        try {
            return this.authApi.get('users/users');
        }
        catch (e) {
            console.error(e);
        }
    };
    AuthService.prototype.completeAuthentication = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, userInfo, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4 /*yield*/, this.manager.signinRedirectCallback()];
                    case 1:
                        _a.user = _b.sent();
                        userInfo = this.jwtHelper.decodeToken(this.user.access_token);
                        this.userMeService.setUserInfo(userInfo);
                        this._authNavStatusSource.next(this.isAuthenticated());
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _b.sent();
                        console.error('Authentication error: ', e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.logInIdentityServer = function () {
        return this.manager.signinRedirect();
    };
    AuthService.prototype.logOut = function () {
        this.clearStorage();
        this.manager.signoutRedirect({ 'id_token_hint': this.user.id_token });
    };
    AuthService.prototype.silentRefresh = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.manager.signinSilentCallback()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.clearStorage = function () {
        this.storage.remove(TOKEN_STORAGE_KEY);
        this.storage.remove(FEATURES_LEVEL_KEY);
        this.storage.remove(ADMIN_STORAGE_KEY);
        this.storage.remove(BRANCH_ID_STORAGE_KEY);
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.ApiService), i0.inject(i2.LocalStorageService), i0.inject(i3.UserMeService), i0.inject(i4.TranslateService), i0.inject(i5.AuthApiService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
