import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/@core/services/auth/auth.service';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import { EUserRole } from '@app/shared/types/EUserRole';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router,
    private userMeService: UserMeService
  ) {

  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const authenticated = this.auth.isAuthenticated();

    if (state.url.includes('auth-callback') && !authenticated) {
      return true;
    }

    if (!authenticated) {
      await this.auth.logInIdentityServer();
      return false;
    } else {
      const response = await this.userMeService.getUserInfo();
      // allow user with role INVENTURA to go only to stock-taking
      if (response.user.role === EUserRole.INVENTURA) {
        if (state.url.includes('stock-taking')) {
          return authenticated;
        } else {
          await this.router.navigate(['/stock-taking']);
        }
      } else {
        return authenticated;
      }
    }
  }
}
