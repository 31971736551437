/**
 * Timestamps with last time of tag invalidation
 */
export const cacheTags: { [key: string]: number } = {};

/**
 * This function create getter which do only first request for data and others cache -
 * save to queue and resolve it after receiving data. After first success data fetch it
 * return immediately resolved promise. Cached values are only for duplicate arguments which
 * are passed to dataGetter function.
 *
 * @param {Function} dataGetter Function returning data as Promise or normal data for initial data request. Arguments are passed.
 * @param {?number} [cacheTimeout=60000] Cached data maximal age in milliseconds. Default is 60 seconds.
 * @param tags
 *
 * @return {Function} Function doing caching and returning Promise
 */
export function CreateCacher(dataGetter: Function, cacheTimeout: number = 60000, tags: string[] = []): Function {
  const fetched = {};
  const fetching = {};
  const resolveArray = {};
  const fetchTime = {};

  const result = {};

  /**
   * @return {Promise<Object>}
   */
  return function (...args: any[]): Promise<any> {

    // Create signature by arguments so can decide if is already cached
    const argsSignature = JSON.stringify(args);

    // Fetched and not too old?
    if (
      fetched[argsSignature]
      && fetched[argsSignature].getTime() + cacheTimeout > (new Date).getTime()
      && tags.filter((tag) => cacheTags[tag] > fetchTime[argsSignature]).length === 0
    ) {
      return Promise.resolve(result[argsSignature]);
    }

    // First request - fetch data
    if (!fetching[argsSignature]) {
      fetching[argsSignature] = true;
      resolveArray[argsSignature] = [];
      fetchTime[argsSignature] = new Date().getTime();

      // Get data
      const promise = dataGetter.apply(this, args);

      // Save data
      const dataSave = (data: any) => {
        // Save data and cache timestamp
        result[argsSignature] = data;
        fetched[argsSignature] = new Date();
        fetching[argsSignature] = false;

        // Resolve other request for data
        for (const key in resolveArray[argsSignature]) {
          resolveArray[argsSignature][key][0](result[argsSignature]);
        }

        // Remove cached data after timeout
        setTimeout(() => {
          delete result[argsSignature];
          delete fetched[argsSignature];
          delete fetching[argsSignature];
          delete resolveArray[argsSignature];
        }, cacheTimeout);

        return data;
      };

      // Does getter return Promise?
      if (promise && typeof promise.then === 'function') {
        promise
          .then(dataSave)
          .catch((e: any) => {
            // Fetch fail, so enable new data fetch
            fetching[argsSignature] = false;

            // Fire reject on other data requests
            for (const key in resolveArray[argsSignature]) {
              resolveArray[argsSignature][key][1](result[argsSignature]);
            }
          });

        return promise;
      }

      // Getter doesn't return promise, so create new to return
      return Promise.resolve(dataSave(promise));

    }

    // Data fetching in progress, so register to queue
    return new Promise((resolve, reject) => {
      resolveArray[argsSignature].push([resolve, reject]);
    });
  };
}
