import { environment } from '@/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiInterface } from '@app/@core/services/api/api.interface';
import { LocalStorageService } from '@app/@core/services/storage/local-storage.service';
import { TOKEN_STORAGE_KEY } from '@app/@core/services/storage/storage-keys';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService implements ApiInterface {
  public apiEndpoint: string;
  private STATUS_UNAUTHORIZED = 401;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: LocalStorageService,
    ) {
    if (environment.hasOwnProperty('apiMockAll') && environment['apiMockAll'] === true) {
      this.apiEndpoint = environment['apiMockEndpoint'];
    } else {
      this.apiEndpoint = environment.authServerEndpoint;
    }
  }

  public get<T = any>(url: string): Observable<T> {
    return this.resolveApiResponse<T>(this.http.get(`${this.apiEndpoint}${url}`));
  }

  public post<T = any>(url: string, options: {} = {}): Observable<T> {
    return this.resolveApiResponse<T>(this.http.post(`${this.apiEndpoint}${url}`, options));
  }

  public put(url: string, options: {}): Observable<any> {
    return this.resolveApiResponse(this.http.put(`${this.apiEndpoint}${url}`, options));
  }

  public patch(url: string, options: {}): Observable<any> {
    return this.resolveApiResponse(this.http.patch(`${this.apiEndpoint}${url}`, options));
  }

  public delete(url: string, options: {}): Observable<any> {
    return this.resolveApiResponse(this.http.delete(`${this.apiEndpoint}${url}`, options));
  }

  public resolveApiResponse<T = any>(data: Observable<any>): Observable<T> {
    return new Observable(observer => {
      data.subscribe(
        response => {
          observer.next(response);
          observer.complete();
        },
        error => {
          if (error.status === this.STATUS_UNAUTHORIZED || error.status === 403) {
            this.storage.remove(TOKEN_STORAGE_KEY);
            if (!this.router.url.includes('/login')) {
              // TODO: vymazat vsechny puvodni login veci v celem projektu az bude hotove
              // this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url}});
            }
          }
          observer.error(this.parseErrorResponse(error));
          observer.complete();
        });
    });
  }

  public parseErrorResponse(error: string): string {
    console.log({error});
    let errorResponse = error['error'];
    if (typeof errorResponse === 'string') {
      errorResponse = {
        error: {message: errorResponse}
      };
    }
    if (errorResponse && errorResponse['error'] === undefined) {
      errorResponse['error'] = {};
    }
    if (errorResponse && errorResponse['error']['message'] === undefined) {
      errorResponse['error']['message'] = 'Undefined error';
    }
    return errorResponse;
  }
}
