import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/@core/services/api/api.service';
import { AuthApiService } from '@app/@core/services/api/auth-api.service';
import { AuthUserInfo } from '@app/@core/services/auth/models/auth-user.model';
import {
  ADMIN_STORAGE_KEY,
  BRANCH_ID_STORAGE_KEY, FEATURES_LEVEL_KEY,
  TOKEN_STORAGE_KEY
} from '@app/@core/services/storage/storage-keys';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { LocalStorageService } from '../storage/local-storage.service';
import { UserManager, User } from 'oidc-client';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // Observable navItem source
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  public authNavStatus$ = this._authNavStatusSource.asObservable();

  private manager = new UserManager(environment.clientAuthSettings);
  private jwtHelper = new JwtHelperService();
  private user: User = null;
  private userId: string = null;

  constructor(
    private api: ApiService,
    private storage: LocalStorageService,
    private userMeService: UserMeService,
    private translate: TranslateService,
    private authApi: AuthApiService
  ) {
    this.manager.events.addUserSignedOut(() => {
      this.clearStorage();
      this.manager.signoutRedirect();
    });
    this.manager.getUser().then(user => {
      this.user = user;
      this._authNavStatusSource.next(this.isAuthenticated());
    });
  }

  public isAuthenticated(): boolean {
    const isAuthenticated = this.user !== null && !this.user.expired;

    if (isAuthenticated && this.userId === null && this.user !== null) {
      // TODO: do not duplicate token and keep it in user? Then inject User to work with it?
      this.storage.store(TOKEN_STORAGE_KEY, this.user.access_token);
    }

    return isAuthenticated;
  }

  public isAdmin(): boolean {
    return this.storage.get(ADMIN_STORAGE_KEY) === 'true';
  }

  public getToken(): string {
    const token = this.storage.get(TOKEN_STORAGE_KEY);
    return token !== null ? token : '';
  }

  public getBranchId(): string {
    let branchId = this.storage.get(BRANCH_ID_STORAGE_KEY);
    // @ts-ignore
    if (branchId === 0 || branchId === '0') {
      branchId = '';
    }
    return branchId !== null ? branchId : '';
  }

  public setBranchId(id: string): void {
    this.storage.store(BRANCH_ID_STORAGE_KEY, id);
  }

  public getUsers(): Observable<AuthUserInfo[]> {
    try {
      return this.authApi.get('users/users') as Observable<AuthUserInfo[]>;
    } catch (e) {
      console.error(e);
    }
  }

  public async completeAuthentication(): Promise<void> {
    try {
      this.user = await this.manager.signinRedirectCallback();
      const userInfo = this.jwtHelper.decodeToken(this.user.access_token);
      this.userMeService.setUserInfo(userInfo);

      this._authNavStatusSource.next(this.isAuthenticated());
    } catch (e) {
      console.error('Authentication error: ', e);
    }
  }

  public logInIdentityServer(): Promise<void> {
    return this.manager.signinRedirect();
  }

  public logOut(): void {
    this.clearStorage();
    this.manager.signoutRedirect({ 'id_token_hint': this.user.id_token });
  }

  public async silentRefresh(): Promise<void> {
    await this.manager.signinSilentCallback();
  }

  public clearStorage(): void {
    this.storage.remove(TOKEN_STORAGE_KEY);
    this.storage.remove(FEATURES_LEVEL_KEY);
    this.storage.remove(ADMIN_STORAGE_KEY);
    this.storage.remove(BRANCH_ID_STORAGE_KEY);
  }
}
