import { cacheTags, CreateCacher } from './cacher';

/**
 * This decorator create method which do only first request for data and others cache -
 * save to queue and resolve it after receiving data. After first success data fetch it
 * return immediately resolved promise. Cached values are only for duplicate arguments which
 * are passed to dataGetter function.
 *
 * @param {?number} [cacheTimeout=60000] Cached data maximal age in milliseconds. Default is 60 seconds.
 * @param {string[]} [tags=[]] Array og tags used for invalidation by invalidateCache annotation
 */
export function cache(cacheTimeout: number = 60000, tags: string[] = []): Function {
  return function (target: any, keyName: string, descriptor: TypedPropertyDescriptor<any>): any {
    return {
      value: CreateCacher(descriptor.value, cacheTimeout, tags),
    };
  };
}

export function invalidateCache(tags: string[]): Function {
  return function (target: any, keyName: string, descriptor: TypedPropertyDescriptor<any>): any {
    return {
      value: function (...args: any[]): any {

        const invalidate = () => {
          for (const tag of tags) {
            cacheTags[tag] = new Date().getTime();
          }
        };

        const result = descriptor.value.apply(this, args);

        // Invalidate after Promise is resolved if is returned
        if (typeof result === 'object' && typeof result.then === 'function') {
          result.then((response: any) => {
            invalidate();
            return response;
          });
        } else {
          invalidate();
        }

        return result;
      },
    };
  };
}

export { CreateCacher };
