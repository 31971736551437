var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AuthService } from '@/app/@core/services/auth/auth.service';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureLevelService } from '@app/@core/services/feature-level/feature-level.service';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import { LayoutService } from '@app/@core/utils';
import { NbSidebarService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { EUserRole } from '@app/shared/types/EUserRole';
var SideNavComponent = /** @class */ (function () {
    function SideNavComponent(router, features, translate, auth, userMeService, sidebarService, layoutService) {
        this.router = router;
        this.features = features;
        this.translate = translate;
        this.auth = auth;
        this.userMeService = userMeService;
        this.sidebarService = sidebarService;
        this.layoutService = layoutService;
        this.menuItems = [];
    }
    SideNavComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadMenuItems()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SideNavComponent.prototype.navigate = function (url) {
        return this.router.navigate([url]);
    };
    SideNavComponent.prototype.loadMenuItems = function () {
        return __awaiter(this, void 0, void 0, function () {
            var menuItems, _loop_1, this_1, _i, menuItems_1, each;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getMenuItems()];
                    case 1:
                        menuItems = _a.sent();
                        _loop_1 = function (each) {
                            this_1.menuItems.push(each);
                            this_1.translate.stream(each.title).subscribe(function (res) { each.title = res; });
                            if (each.hasOwnProperty('children')) {
                                var _loop_2 = function (eachChild) {
                                    this_1.translate.stream(eachChild.title).subscribe(function (res) {
                                        eachChild.title = res;
                                    });
                                };
                                for (var _i = 0, _a = each.children; _i < _a.length; _i++) {
                                    var eachChild = _a[_i];
                                    _loop_2(eachChild);
                                }
                            }
                        };
                        this_1 = this;
                        for (_i = 0, menuItems_1 = menuItems; _i < menuItems_1.length; _i++) {
                            each = menuItems_1[_i];
                            _loop_1(each);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SideNavComponent.prototype.getMenuItems = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userMeService.getUserInfo()];
                    case 1:
                        response = _a.sent();
                        if (response.user.role === EUserRole.INVENTURA) {
                            this.sidebarService.collapse('menu-sidebar');
                            this.layoutService.changeLayoutSize();
                            return [2 /*return*/, [{
                                        title: 'page.stockTaking.title',
                                        link: '/stock-taking',
                                        icon: 'fa fa-file'
                                    }]];
                        }
                        else {
                            return [2 /*return*/, [
                                    {
                                        title: 'dashboard.title',
                                        link: '/',
                                        icon: 'fa fa-home',
                                        home: true,
                                    },
                                    {
                                        title: 'branches.title',
                                        link: '/branches',
                                        icon: 'fa fa-building',
                                        hidden: !this.auth.isAdmin()
                                    },
                                    {
                                        title: 'stocks.title',
                                        link: '/stocks',
                                        icon: 'fa fa-warehouse',
                                    },
                                    {
                                        title: 'stockCards.title',
                                        link: '/stock-cards',
                                        icon: 'fa fa-cube'
                                    },
                                    {
                                        title: 'recipes.title',
                                        link: '/recipes',
                                        icon: 'fa fa-book'
                                    },
                                    // TODO: uncomment when implemented
                                    // {
                                    //   title: 'suppliers.title',
                                    //   link: '/suppliers',
                                    //   hidden: !this.features.isAvailable(this.features.LEVEL_DEV),
                                    //   icon: 'fa fa-truck'
                                    // },
                                    // {
                                    //   title: 'users.title',
                                    //   link: '/users',
                                    //   icon: 'fa fa-user',
                                    //   hidden: !this.auth.isAdmin()
                                    // },
                                    // {
                                    //   title: 'page.stats.title',
                                    //   link: '/stats',
                                    //   hidden: !this.features.isAvailable(this.features.LEVEL_DEV),
                                    //   icon: 'fa fa-chart-bar'
                                    // },
                                    // {
                                    //   title: 'page.reports.title',
                                    //   link: '/reports',
                                    //   hidden: !this.features.isAvailable(this.features.LEVEL_DEV),
                                    //   icon: 'fa fa-file'
                                    // },
                                    {
                                        title: 'page.stockTaking.title',
                                        link: '/stock-taking',
                                        icon: 'fa fa-file'
                                    },
                                    {
                                        title: 'externalLink.groupTitle',
                                        group: true,
                                    },
                                    {
                                        title: 'externalLink.cinkCloud',
                                        url: 'https://cink.cloud/',
                                        target: '_blank',
                                        icon: 'fa fa-dollar-sign'
                                    }
                                ]];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return SideNavComponent;
}());
export { SideNavComponent };
