var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Router } from '@angular/router';
import { AuthApiService } from '@app/@core/services/api/auth-api.service';
import { LocalStorageService } from '@app/@core/services/storage/local-storage.service';
import { ADMIN_STORAGE_KEY, BRANCH_ID_STORAGE_KEY } from '@app/@core/services/storage/storage-keys';
import { Time } from '@app/@core/utils/Time';
import { cache } from '@app/@core/utils/tscache/cache';
import { ApiService } from '@app/@core/services/api/api.service';
import { CrudSingleService } from '@app/@core/services/crud/crud-single.service';
import { ETags } from '@app/shared/types/ETags';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../api/api.service";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/router";
import * as i4 from "../storage/local-storage.service";
import * as i5 from "../api/auth-api.service";
var UserMeService = /** @class */ (function (_super) {
    __extends(UserMeService, _super);
    function UserMeService(api, translate, router, storage, authApi) {
        var _this = _super.call(this, api) || this;
        _this.api = api;
        _this.translate = translate;
        _this.router = router;
        _this.storage = storage;
        _this.authApi = authApi;
        _this.userInfo = null;
        _this._urlNameSpace = '/user-info';
        _this.supportedMethods = ['get', 'update'];
        _this.dataSubject = new BehaviorSubject(null);
        return _this;
    }
    UserMeService.prototype.setLang = function (lang) {
        var _this = this;
        return new Observable(function (observer) {
            _this.getUserInfo(true).then(function (userInfoResponse) {
                userInfoResponse.user.lang = lang;
                // TODO: lang
                _this.api.put(_this._urlNameSpace, userInfoResponse).subscribe(function (data) {
                    observer.next(data['data']);
                });
            });
        });
    };
    UserMeService.prototype.setUserInfo = function (userInfo) {
        try {
            var parsed = JSON.parse(userInfo.user_info);
            this.userInfo = parsed;
        }
        catch (e) {
            console.error(e);
        }
    };
    UserMeService.prototype.getUserInfo = function (fromCache) {
        if (fromCache === void 0) { fromCache = false; }
        return __awaiter(this, void 0, void 0, function () {
            var user, companyName, response, userRole, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.authApi.get('users/user-info').toPromise()];
                    case 1:
                        user = _a.sent();
                        return [4 /*yield*/, this.api.get('/users/my-company-name').toPromise()];
                    case 2:
                        companyName = _a.sent();
                        response = {
                            user: user,
                            company: {
                                name: companyName,
                                id: user.companyId
                            },
                        };
                        return [4 /*yield*/, this.api.get('/users/my-role').toPromise()];
                    case 3:
                        userRole = _a.sent();
                        response.user.role = userRole.name;
                        if (!this.storage.get(BRANCH_ID_STORAGE_KEY)) {
                            this.storage.store(BRANCH_ID_STORAGE_KEY, userRole.branchId);
                        }
                        this.storage.store(ADMIN_STORAGE_KEY, userRole.isAdmin);
                        this.translate.use(response.user.lang);
                        return [2 /*return*/, response];
                    case 4:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UserMeService.prototype.getBranches = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUserInfo()];
                    case 1:
                        userResponse = _a.sent();
                        return [4 /*yield*/, this.api.get("/users/" + userResponse.user.id + "/branches/").toPromise()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    UserMeService.ngInjectableDef = i0.defineInjectable({ factory: function UserMeService_Factory() { return new UserMeService(i0.inject(i1.ApiService), i0.inject(i2.TranslateService), i0.inject(i3.Router), i0.inject(i4.LocalStorageService), i0.inject(i5.AuthApiService)); }, token: UserMeService, providedIn: "root" });
    __decorate([
        cache(Time.MINUTE * 10, [ETags.USER_INFO]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", Promise)
    ], UserMeService.prototype, "getUserInfo", null);
    return UserMeService;
}(CrudSingleService));
export { UserMeService };
