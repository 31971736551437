// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  staging: true,
  apiEndpoint: 'https://warehouse.staging.cink.cloud/api/v1',
  authServerEndpoint: 'https://auth.staging.cink.cloud/',
  recaptchaKey: '6Lc1XHEUAAAAAE3u4RYINZUj1ROB4xVupKxQDuYB',
  hmr: false,
  clientAuthSettings: {
    authority: 'https://auth.staging.cink.cloud',
    client_id: 'cink_angular',
    redirect_uri: 'https://warehouse.staging.cink.cloud/auth-callback',
    post_logout_redirect_uri: 'https://warehouse.staging.cink.cloud',
    response_type: 'id_token token',
    scope: 'openid profile api1 roles user_info user_company',
    filterProtocolClaims: true,
    loadUserInfo: true,
    automaticSilentRenew: true,
    silent_redirect_uri: 'https://warehouse.staging.cink.cloud/silent-refresh.html'
  }
};
